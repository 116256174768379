<template>
  <div class="data-exporter">
    <loading-box :loading="loading"></loading-box>
    <h4>Fields to Export:</h4>
    <div v-if="!loading">
      <div class="green-divider"></div>
      <div class="field-container">
        <div class="field-header col-5">Field Name</div>
        <div class="field-header col-2">Type</div>
        <div class="field-header col-3">Transform</div>
        <div class="field-header col-1">Include</div>
      </div>
      <div v-for="(field, idx) of availableFields" v-bind:key="idx" class="field-container">
        <div class="field-label col-5">{{field.name}}</div>
        <div class="field-label col-2">{{field.type}}</div>
        <div class="field-label col-3" v-if="transformers.hasOwnProperty(field.type)">
          <b-select v-model="field.transformer" :options="getTransformers(field)"></b-select>
        </div>
        <div class="field-label col-3" v-if="!transformers.hasOwnProperty(field.type)">
          Not Available
        </div>
        <div class="field-label col-1 text-center">
          <b-checkbox v-model="field.selected"></b-checkbox>
        </div>
      </div>
      <div class="green-divider"></div>
    </div>
    <div class="modal-footer">
      <b-button @click="clickExport" class="button btn">Export to CSV</b-button>
      <b-button v-if="modal_id"  @click="$bvModal.hide(modal_id)" class="button btn">Cancel</b-button>
    </div>
  </div>
</template>
<script>
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import moment from 'moment'
import LoadingBox from '@/components/helpers/LoadingBox'
export default {
  name: 'data-exporter',
  components: {
    LoadingBox
  },
  props: {
    data: Array,
    fields: Array,
    filename: String,
    modal_id: String
  },
  data: function () {
    return {
      loading: true,
      exportCounter: 0,
      exportFileName: '',
      // Note: The FIRST item in the transformers list for an object type will be the default.
      transformers: {
        'string': [
          {
            text: 'None',
            func: null,
            value: null
          },
          {
            text: 'Datestamp',
            value: 'datestamp',
            func: this.formatDatestamp,
          },
          {
            text: 'To Uppercase',
            value: 'toupper',
            func: this.formatToUpper
          }
        ],
        'array': [
          {
            text: 'Item Count',
            value: 'count',
            func: this.formatArrayCount
          },
          {
            text: 'Dump Array (to String)',
            value: 'arraytostring',
            func: this.formatArrayToString
          }
        ],
        'object': [
          {
            text: 'Dump JSON (to String)',
            value: 'json',
            func: this.formatToString
          }
        ]
      },
      dataInternal: [],
      availableFields: [
        {
          name: '',
          selected: false,
          type: '',
          transformer: null
        }
      ]
    }
  },
  async mounted () {
    if (this.filename) {
      this.exportFileName = this.filename
    } else {
      this.exportFileName = 'ProtektCSVExport'
    }
    if (this.data) {
      this.dataInternal = this.data
    } else {
      // TODO - Possibly take an API endpoint instead of data?
      ErrorHelper.displayGeneralErrorToast('No Data Provided to Exporter', 'No Data Found')
      return
    }
    if (this.fields) {
      this.availableFields = this.generateFieldArray(this.fields)
    } else {
      let fieldSet = new Set()

      this.dataInternal.forEach(row => {
        for (let prop in row) {
          fieldSet.add(prop)
        }
      })
      this.availableFields = this.generateFieldArray((Array.from(fieldSet)))
    }
    this.loading = false
  },
  methods: {
    generateFieldArray: function (array) {
      return array.map(field => {
        let transformer = null
        let type = this.findFieldType(field, this.dataInternal)
        // Set the first transformer option as the default for this type
        if (this.transformers.hasOwnProperty(type)) {
          transformer = this.transformers[type][0].value
        }

        return {
          name: field,
          selected: true,
          type: type,
          transformer: transformer
        }
      })
    },
    getTransformers(field) {
      let transformerOptions
      if (!Object.hasOwn(this.transformers, field.type)) {
        return null
      } else {
        transformerOptions = [...this.transformers[field.type]]
      }
      if (field.type === 'object') {
        let row = this.dataInternal.find(row => row[field.name] !== null && row[field.name] !== undefined)
        // Just use the first row that has a valid Object. Not perfect, but good enough for now.
        if (row) {
          // transformer.concat(Object.keys(this.dataInternal[0][field]))
          let propOptions = Object.keys(row[field.name]).map((propName) => {
            return {
              text: `Prop: ${propName}`,
              func: (data) => this.formatPropToString(data, propName),
              value: `${field.name}-prop-${propName}`
            }
          })
          transformerOptions = transformerOptions.concat(propOptions)
        }
      }
      return transformerOptions
    },
    // Find the type of the field
    findFieldType: function (fieldName, array) {
      for (let row of array) {
        if (row.hasOwnProperty(fieldName) && row[fieldName] !== null) {
          if (typeof row[fieldName] === 'object' && Array.isArray(row[fieldName])) {
            return 'array'
          } else {
            return typeof row[fieldName]
          }
        }
      }
      return 'unknown'
    },
    clickExport: function () {
      let filename = this.exportFileName + this.exportCounter
      this.exportToCSV(this.dataInternal, filename)
      this.exportCounter++
    },
    exportToCSV: function (data, fileName) {
      // PapaParse Configuration
      let config = {
        quotes: false, // or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: true,
        newline: '\r\n',
        skipEmptyLines: false, // or 'greedy',
        columns: null // or array of strings
      }

      let fields = this.availableFields.filter(field => field.selected)
      let exportData = []
      // To avoid contaminating the source data we need to create new objects to export, since we will be
      // applying formatting to the values to make them human-readable.
      for (let row of data) {
        exportData.push(this.parseRow(row, fields))
      }
      console.log(exportData)
      let csvData = this.$papa.unparse(exportData, config)
      this.$papa.download(csvData, fileName)
    },
    // Parse a row of data, formatting as required, returns a object equivilent to one row
    parseRow: function (rowData, fields) {
      let row = {}
      for (let field of fields) {
        // Handle missing elements
        if (!rowData.hasOwnProperty(field.name)) {
          row[field.name] = ''
        } else if (rowData[field.name] === null) {
          row[field.name] = 'null'
        } else if (field.transformer) {
          console.log(field.transformer)
          row[field.name] = this.transformFunctions[field.transformer](rowData[field.name])
        } else {
          row[field.name] = rowData[field.name]
        }
      }
      return row
    },
    // *******************************
    //  Data Transformation Functions
    // *******************************
    formatDatestamp: function (data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    formatToUpper: function (data) {
      return data.toString().toUpperCase()
    },
    formatToString: function (data) {
      return JSON.stringify(data)
    },
    formatArrayToString: function (data) {
      return data.toString()
    },
    formatArrayCount: function (data) {
      return data.length
    },
    formatPropToString: function (data, propName) {
      return data[propName].toString()
    },
  },
  computed: {
    transformFunctions () {
      let transformFuncs = {}
      for (let field of this.availableFields) {
        let options = this.getTransformers(field)
        if (options) {
          options.forEach((option) => {
            transformFuncs[option.value] = option.func
          })
        }
      }
      return transformFuncs
    }
  },
  watch: {
    data (newData) {
      this.dataInternal = newData
    },
    fields (newFields) {
      this.availableFields = this.generateFieldArray(newFields)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../variables';

.data-exporter {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
}

.field-container {
  display: flex;
  flex-direction: row;
}

.field-label {
}

.field-header {
  color: $theme-color-primary-3;
  text-align: center;
}

.export-button {
  max-width: 50%
}

</style>
